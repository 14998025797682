$(document).ready(function () {
	
	console.log('Loading v3 scripts...');
	
	// Function to check if the Vimeo iframe is in the middle of the screen
	function isIframeInMiddle() {
		var iframe = $('#vimeoIframe');
		
		if (!iframe.length) {
			return;
		}
		
		var iframeTop = iframe.offset().top;
		var iframeBottom = iframeTop + iframe.height();
		var windowHeight = $(window).height();
		var windowMiddle = windowHeight / 2;
		
		return (iframeTop < windowMiddle && iframeBottom > windowMiddle);
	}
	
	// Function to autoplay the Vimeo video
	function autoplayVimeoVideo() {
		var iframe = $('#vimeoIframe')[0]; // Get the native iframe element
		var player = new Vimeo.Player(iframe);
		
		// Autoplay the video
		player.play();
	}
	
	// Event handler for the scroll event
	$(window).scroll(function() {
		// Check if the Vimeo iframe is in the middle and autoplay if true
		if (isIframeInMiddle()) {
			console.log('middle');
			autoplayVimeoVideo();
		}
	});
	
	$('.tags').selectize({
		plugins: ['remove_button'],
		preload: true,
		persist: true,
		create: true,
		valueField: 'id',
		labelField: 'name',
		searchField: ['name'],
		options: [],
		render: {
			item: function (item, escape) {
				return '<div>' + (item.name ? '<span>' + escape(item.name) + '</span>' : '') + '</div>';
			},
			option: function (item, escape) {
				var name = item.name || item.name;
				return '<div class="select-holder">' + '<span class="select-label">' + escape(name) +
				'</span>' + '</div>';
			}
		},
		load: function (query, callback) {
			//if (!query.length) return callback();
			$.ajax({
				url: '/api/picklist/tags',
				type: 'GET',
				dataType: 'json',
				data: {
					name: query
				},
				error: function () {
					callback();
				},
				success: function (res) {
					callback(res);
				}
			});
		}
	});
	
	// Honeycomb
	$('.hex-grid__item').click(function () {
		
		// Toggles honeycomb status
		$(this).toggleClass('selected');
		$(this).toggleClass('Clicked');
		
		// Disables/Enables other honeycombs
		var isClicked = document.getElementsByClassName('Clicked');
		if (isClicked.length === 3) {
			$('.hex-grid__list .hex-grid__item:not(.Clicked)').addClass('Disabled');
			$('.hex-grid__list .hex-grid__item:not(.Clicked)').children().addClass('parent_disabled');
		};
		
		if (isClicked.length < 3) {
			$('.hex-grid__list .hex-grid__item:not(.Clicked)').removeClass('Disabled');
			$('.hex-grid__list .hex-grid__item:not(.Clicked)').children().removeClass('parent_disabled');
		};
		
		var Target = $(this).attr('data-target');
		console.log(Target);
		$('#' + Target).toggleClass('hidden');
	});
	
	
	$('#pa-external .pa_ea').remove();
	$('#pa-internal .pa_ia').remove();
	$('#pa-ita .pa_it').remove();
	$('#pa-financial-due-diligence .pa_fdd').remove(); 
	$('#pa-valuations .pa_v').remove(); 
	$('#pa-post-merger-integrations .pa_pmi').remove();
	$('#pa-capital-markets-accounting-advisory-services .pa_cmaas').remove();
	$('#pa-capital-markets-accounting-advisory-services .pa_cmaas').remove(); 
	
	var PointsHeight = $('#PointsHeight .container').height();
	$('.points_background').css('height', PointsHeight+'px');
	
	
	feather.replace()
	
	$("a").on('click', function(event) {
		if (this.hash !== "") {
			event.preventDefault();
			var hash = this.hash;
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 800, function(){
				window.location.hash = hash;
			});
		}
	});
	
	$('#navbarDropdownExpand').click(function () {
		$('.expander_menu').toggleClass('showme');
	});
	
	$('.nav-item').click(function () {
		$('.expander_menu').removeClass('showme');
	});
	
	if ( $( "#waypoint" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('waypoint'),
			handler: function(direction) {
				console.log('Scrolled to waypoint!')
				
				$('.count').each(function () {
					var $this = $(this);
					jQuery({ Counter: 0 }).animate({ Counter: $this.attr('data-stop') }, {
						duration: 2000,
						easing: 'swing',
						step: function (now) {
							$this.text(Math.ceil(now));
						}
					});
				});
			},
			offset: 400
		})
		
	};
	
	if ( $( "#float_zone_one" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('float_zone_one'),
			handler: function(direction) {
				$('.float_001').toggleClass('floatUp');
				$('.line_float_one').toggleClass('growEase');
			},
			offset: 200
		})
		
	};
	
	if ( $( "#movement" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('movement'),
			handler: function(direction) {
				$('.line_float_two').toggleClass('growEase_two');
			},
			offset: 300
		})
		
	};
	
	if ( $( "#survive" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('survive'),
			handler: function(direction) {
				$('.line_float_pa').toggleClass('growEase_pa');
			},
			offset: 300
		})
		
	};
	
	if ( $( "#benefits" ).length ) { 
		
		var waypoint = new Waypoint({
			element: document.getElementById('benefits'),
			handler: function(direction) {
				$('.bar_one').toggleClass('bar_one_height');
				$('.bar_two').toggleClass('bar_two_height');
			},
			offset: 300
		})
		
	};
	
	if ( $( "#benefitsmobile" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('benefitsmobile'),
			handler: function(direction) {
				$('.bar_one').toggleClass('bar_one_length');
				$('.bar_two').toggleClass('bar_two_length');
			},
			offset: 300
		})
		
	};
	
	if ( $( "#pa_way" ).length ) {
		
		var waypoint = new Waypoint({
			element: document.getElementById('pa_way'),
			handler: function(direction) {
				$('.cloud_float').toggleClass('float_right');
			},
			offset: 300
		})
		
	};
	
	$(".pa_link").on('click', function(event) {
		$(this).addClass('active').siblings().removeClass('active');
		var PATarget = $(this).data('target');
		$("." + PATarget).addClass('shown').removeClass('hidden').siblings().removeClass('shown').addClass('hidden');
	});
	
	if ($('.comparison').length) {
		var SpacerHeight = $(".m_head").height();
		$('.head_spacer').css("height", SpacerHeight + 60 + "px");
		$('.target_wrapper').css("height", SpacerHeight + 60 + "px");
	}
	
	const swiper = new Swiper('.swiper-testimonials', {
		speed: 400,
		spaceBetween: 100,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		autoplay: {
			delay: 5000,
		},
	});
	
	const swiperAnimation = new SwiperAnimation();
	
	const swiperhome = new Swiper('.swiper-home', {
		speed: 1400,
		loop: true,
		slidesPerView: 1,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			init: function() {
				swiperAnimation.init(this).animate();
			},
			slideChange: function() {
				swiperAnimation.init(this).animate();
			}
		}
	});
	
	
	
	const partnermobile = new Swiper('.swiper-partnermobile', {
		speed: 400,
		spaceBetween: 100,
		slidesPerView: 1,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		autoplay: {
			delay: 5000,
		},
	});
	
	const paMobileSwiper = new Swiper('.swiper-pa_mobile', {
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	});
	
	const paSwiper = new Swiper('.pa_swiper', {
		speed: 400,
		slidesPerView: 4,
		spaceBetween: 0,
		preventClicks: false,
		preventClicksPropagation: false,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		autoplay: {
			delay: 5000,
		},
		breakpoints: {
			340: {
				slidesPerView: 1,
			},
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 4,
			},
		},
	});
	
	const swiperhiring = new Swiper('.hiring_slider', {
		speed: 400,
		spaceBetween: 100,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		autoplay: {
			delay: 10000,
		},
	});
	
	const videoSlider = new Swiper('.video_slider', {
		speed: 400,
		spaceBetween: 100,
		pagination: {
			el: '.video_pagination',
			type: 'bullets',
			clickable: true,
		},
		autoplay: {
			delay: 10000,
		},
		breakpoints: {
			340: {
				slidesPerView: 1,
			},
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 3,
			},
		},
	});
	
	const swiperpodcasts = new Swiper('.swiper-podcasts', {
		speed: 1400,
		loop: true,
		slidesPerView: 1,
		autoHeight: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			init: function() {
				swiperAnimation.init(this).animate();
			},
			slideChange: function() {
				swiperAnimation.init(this).animate();
			}
		}
	});
	
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('header').outerHeight();
	
	$(window).scroll(function(event){
		didScroll = true;
	});
	
	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);
	
	function hasScrolled() {
		var st = $(this).scrollTop();
		
		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;
		
		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
			// Scroll Down
			$('#header_wrap').removeClass('nav-down').addClass('nav-up');
		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {
				$('#header_wrap').removeClass('nav-up').addClass('nav-down');
			}
		}
		
		lastScrollTop = st;
	}
	
	$(window).scroll(function() {
		
		if ($(this).scrollTop() > 400){  
			$('#header_wrap').addClass("header_dark");
		}
		else{
			$('#header_wrap').removeClass("header_dark");
		}
	});	
	
	var sticky = new Sticky('.stickthis', { marginTop: 200 });
	var sticky = new Sticky('.stickthisicon', { marginTop: 200 });
	var sticky = new Sticky('.stickthisstar');
	
	if ( $( ".carousel-slider" ).length ) {
		const sliderEl = document.querySelector('.carousel-slider');
		createCarouselSlider(sliderEl);
		
	};
	
	
	// v2 scripts not included //
	
	// Mobile numbers
	$(".mobileNumber").intlTelInput({
		autoPlaceholder: "aggressive",
		initialCountry: "za",
		preferredCountries: ["za"],
		// preferredCountries: ["za", "us", "au", "uk", "ph"],
		// United States code first  Then Australia  Then United Kingdom  Then South Africa Then Phillipines
		placeholderNumberType: 'MOBILE',
		separateDialCode: true,
		customPlaceholder: function (selectedCountryPlaceholder) {
			return "Mobile Number e.g. " + selectedCountryPlaceholder;
		},
		hiddenInput: "mobile_full",
		utilsScript: "/js/utils.js"
	});
	
	// Mobile numbers
	$(".mobileNumberUS").intlTelInput({
		autoPlaceholder: "aggressive",
		initialCountry: "us",
		preferredCountries: ["us", "za", "au"],
		// preferredCountries: ["za", "us", "au", "uk", "ph"],
		// United States code first  Then Australia  Then United Kingdom  Then South Africa Then Phillipines
		placeholderNumberType: 'MOBILE',
		separateDialCode: true,
		customPlaceholder: function (selectedCountryPlaceholder) {
			return "Mobile Number e.g. " + selectedCountryPlaceholder;
		},
		hiddenInput: "mobile_full",
		utilsScript: "/js/utils.js"
	});
	
	$('.indicator_show').click(function () {
		$('.slide_value').toggleClass('slide_value_show');
	});
	
	function getTimeRemaining(endtime) {
		const total = Date.parse(endtime) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
		const days = Math.floor(total / (1000 * 60 * 60 * 24));
		
		return {
			total,
			days,
			hours,
			minutes,
			seconds
		};
	}
	
	function initializeClock(id, endtime) {
		const clock = document.getElementById(id);
		const daysSpan = clock.querySelector('.days');
		const hoursSpan = clock.querySelector('.hours');
		const minutesSpan = clock.querySelector('.minutes');
		const secondsSpan = clock.querySelector('.seconds');
		
		function updateClock() {
			const t = getTimeRemaining(endtime);
			
			daysSpan.innerHTML = t.days;
			hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
			minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
			secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
			
			if (t.total <= 0) {
				clearInterval(timeinterval);
			}
		}
		
		updateClock();
		const timeinterval = setInterval(updateClock, 1000);
	}
	
	var element = document.getElementById('clockdiv');
	
	if (element != null) {
		
		const dataID = element.getAttribute('data-deadline');
		const deadline = dataID;
		initializeClock('clockdiv', deadline);
		
	}
	
	$(window).scroll(function() {
        if ($(".mcoe_holder").length) {
            var divTop = $(".mcoe_holder").offset().top;
            var scrollTop = $(window).scrollTop();
            
            if (scrollTop >= divTop) {
                $(".mcoe_inner").addClass("mcoe_innerfull");
                
                setTimeout(function() {
                    $(".coe_one").addClass("coe_active");
                }, 100);
                
                setTimeout(function() {
                    $(".coe_two").addClass("coe_active");
                }, 1600);
                
                setTimeout(function() {
                    $(".coe_three").addClass("coe_active");
                }, 2600);
                
                setTimeout(function() {
                    $(".coe_four").addClass("coe_active");
                }, 4000);
            }
        }
	});
	
	
	const ebpswiper = new Swiper('.swiper-ebp', {
		direction: "vertical",
		slidesPerView: 1,
		spaceBetween: 30,
		autoplay: {
			delay: 3000,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		}
	});
	
	const ebp_mobilechartswiper = new Swiper('.ebp_mobilechart', {
		spaceBetween: 100,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: ".swiper-button-next_ebp",
			prevEl: ".swiper-button-prev_ebp",
		},
	});
	
	const ebpswipermobile = new Swiper('.swiper-ebpmobile', {
		spaceBetween: 100,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
	});
	
	
	console.log('Loaded v3 scripts!');
	console.log('%cNow theres room to grow', 'color: white; background: #2B6AAD; font-size: 14px');
	
});
